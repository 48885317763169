@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

body {
    color: white;
    font-family: 'Space Grotesk', serif;
    background: black url("media/GreenHorizion1BGv2.png") repeat center;
}


.main-menu-top-bar {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(25,25,25,0.5);
}


.main-menu-top-bar-logo {
    padding-top: 4px;
    /*max-width: 50px;*/
}


.main-menu-top-bar-item {
    color: white;
    text-decoration: unset;
}

.main-menu-top-bar-item:hover {
    background: grey;
    cursor: pointer;
    text-decoration: underline;
}




.main-menu-primary-display {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main-menu-primary-display-row-1 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.retail-logo-container {
    width: 1280px;
    height: 576px;
    background-image: url("media/retail-logo-background.png");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: clip;
}

.retail-play-now-container {
    width: 351px;
    height: 78px;
    background-image: url("media/arrows.png");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
}

.retail-play-now-container:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}


.main-menu-primary-display-row-2 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
}


.main-menu-primary-display-logo {
    max-width: 380px;
}

.main-menu-primary-display-row-3 {
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
}

.hsh-manager-description {
    max-width: 500px;
    text-align: justify;
    padding-left: 1rem;
    padding-right: 1rem;
}

.hsh-vacancy-description {
    max-width: 500px;
    text-align: justify;
    padding-left: 1rem;
    padding-right: 1rem;
}

.main-menu-primary-display-row-4 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 400px;
}

.main-menu-primary-display-row-5 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 200px;
}

.main-menu-bottom-spacer {
    min-height: 250px;
}

.social-logo {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 100px;
    min-width: 100px;
}

.main-menu-primary-display-row-6 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    min-height: 400px;
    text-align: center;
}

.main-menu-product-window {
    min-height: 250px;
    min-width: 250px;
    max-width: 300px;
    border: 1px solid grey;
    margin: 1rem;
    background: rgba(250, 250, 250, 0.25);
}

.main-menu-primary-display-row-7 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
